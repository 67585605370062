define(['jquery'], function($) {
  
  var navDropDowns = {
    // global variables
    config: {},
    $body: $('body'),

    showHide: function(eventType, $menuItem) {

      var $theDropDown = $menuItem.find(navDropDowns.config.$dropdown),
        mainMenuWidth = $menuItem.parent().outerWidth(),
        menuItemLeftPosition = $menuItem.position().left,
        dropDownWidth = $theDropDown.outerWidth(),
        offset = menuItemLeftPosition + dropDownWidth,
        hasOffset = (offset > mainMenuWidth),
        hasOverlay = (navDropDowns.config.overlay.hasOverlay === true),
        hasBgcolourTruthy = navDropDowns.config.navLinkColours.foreground !== '',
        hasFgColourTruthy = navDropDowns.config.navLinkColours.background !== '';


      if (hasOffset && menuItemLeftPosition > dropDownWidth) {
        $theDropDown.css({
          right: 0,
          left: 'auto'
        });
      } else if (hasOffset && menuItemLeftPosition < dropDownWidth) {
        $theDropDown.css({
          left: mainMenuWidth - offset + 'px'
        });
      }

      if (eventType === 'mouseenter') {

        if (hasOverlay) (
          navDropDowns.overlaySwitch(1)
        );

        if (hasBgcolourTruthy || hasFgColourTruthy) {
          navDropDowns.navLinkStyle($menuItem, 1);
        }

        $theDropDown.show().addClass(navDropDowns.config.activeClassName);
      } else {
        if (hasOverlay) {
          navDropDowns.overlaySwitch(0);
        }
        if (hasBgcolourTruthy || hasFgColourTruthy) {
          navDropDowns.navLinkStyle($menuItem, 0);
        }
        $theDropDown.hide().removeClass(navDropDowns.config.activeClassName);
      }
    },
    overlaySwitch: function(theSwitch) {

      var $overlayObject = $(navDropDowns.config.overlay.overlayClass);

      if (theSwitch === 1) {
        // Check if overlay exists and add markup if overlay isn't already loaded.

        if (!$overlayObject.length) {
          $('<div />', {
            'class': 'overlay',
            'css': {
              'opacity': 0.2
            }
          }).appendTo(navDropDowns.$body).css('opacity', 0.2).stop().fadeIn(300);
        }

        // Overlay transitions.
        $overlayObject.css('opacity', 0.2).stop().fadeIn(300);

      } else {
        $overlayObject.stop().fadeOut(300);
      }
    },
    navLinkStyle: function($navLink, theSwitch) {
      // Change nav link background if needed
      if (theSwitch === 1) {
        $navLink.css({
          'background-color': navDropDowns.config.navLinkColours.background,
          'color': navDropDowns.config.navLinkColours.foreground
        });
      } else {
        $navLink.css({
          'background-color': '',
          'color': ''
        });
      }

    },
    getColumnWidths: function($theDropDownColumns) {
      // get width of menu panel if there are columns below
      var totalWidth = 0;

      $theDropDownColumns.each(function() {
        totalWidth += parseInt($(this).outerWidth(), 10);
      });

      if ($theDropDownColumns.length > 1) {
        $theDropDownColumns.parent().css({
          width: totalWidth
        });
      }
    },
    hoverControl: function() {
      //show dropdown
      navDropDowns.config.$navlink.has(navDropDowns.config.$dropdown).on(
        {
          hover: function(e) {
            var $this = $(this);
            var $theDropDownColumns = $this.find(navDropDowns.config.$dropdownColumn);

            $theDropDownColumns.length > 1 ? navDropDowns.getColumnWidths($theDropDownColumns) : false;
            navDropDowns.showHide(e.type, $this);
          }
        }
      );
    }
  };

  return {
    init: function(config) {
      navDropDowns.config = config;
      navDropDowns.hoverControl();
    }
  };

});
